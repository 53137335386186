import React from "react";
import { Button } from "@chakra-ui/react";
import { ACTIVE_BUTTON_COLOR } from "../../utils/constant";

const ButtonSecondary = ({
  text,
  handleClick,
  color = ACTIVE_BUTTON_COLOR,
}) => {
  return (
    <Button
      fontSize="12px"
      fontWeight="500"
      padding="6px 12px"
      color={color}
      bg="white"
      borderRadius="100px"
      gap="8px"
      direction="row"
      width="100%"
      height="32px"
      alignItems="center"
      onClick={handleClick}
      border="1px"
      borderColor={color}
      letterSpacing="0.1px"
    >
      {text}
    </Button>
  );
};

export default ButtonSecondary;
