import React from "react";
import { Flex, Skeleton } from "@chakra-ui/react";

const CardSkeleton = ({ isFirstChild, isLastChild }) => {
  return (
    <Flex
      w="200px"
      direction="column"
      marginLeft={isFirstChild ? "16px" : "0"}
      marginRight={isLastChild ? "16px" : "0"}
      padding="8px 0"
      spacing="0"
      bg="#FFFFFF"
      style={{
        boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
        borderRadius: "12px",
      }}
      rowGap="12px"
    >
      <Flex
        direction="row"
        w="200px"
        justifyContent="center"
        borderBottom="1px solid #EFEFEF"
        paddingBottom="8px"
        alignItems="center"
      >
        <Skeleton height="30px" width="180px" />
      </Flex>
      <Flex direction="row" w="200px" justifyContent="center">
        <Skeleton height="40px" width="180px" />
      </Flex>
    </Flex>
  );
};

export default CardSkeleton;
