import React from "react";

import Header from "../../../../components/SecondaryHeader";
import Upcoming from "./modules/Upcoming";
import BottomNavbar from "../../../../components/Navbars/BottomNavbar";
import { Flex } from "@chakra-ui/react";

function UpcomingView() {
  return (
    <>
      <Flex position="sticky" top={0}>
        <Header type="all" title="Mendatang" backLink="/" />
      </Flex>

      <Upcoming />
      <BottomNavbar />
    </>
  );
}

export default UpcomingView;
