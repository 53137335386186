import { format, parseISO } from "date-fns";
import { id } from "date-fns/locale";

// Input: "2024-10-22T14:30:00Z"
// Output: { date: "Selasa, 22 Oktober 2024", time: "14:30" }
export const formatISOtoDayAndHHmm = (isoString) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "EEEE, d MMMM yyyy", { locale: id });
  const formattedTime = format(date, "HH:mm", { locale: id });

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

// Input: "2024-10-22T14:30:00Z"
// Output: { date: "22 Oct 2024", time: "14.30" }
export const formatISOtoDDMMMYYYY = (isoString) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "dd MMM yyyy", { locale: id });
  const formattedTime = format(date, "HH.mm", { locale: id });

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

// Input: "2024-10-22T14:30:00Z"
// Output: { date: "22/10/2024", time: "14:30" }
export const formatISOtoDDMMYYYYAndHHmm = (isoString) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "dd/MM/yyyy", { locale: id });
  const formattedTime = format(date, "HH:mm", { locale: id });

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

// Input: "2024-10-22"
// Output: "Selasa, 22 Oktober 2024"
export const formatYYYYMMDDToDay = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("id-ID", options).format(date);
  return formattedDate;
};

// Input: new Date("2024-10-22"), "14:30"
// Output: "2024-10-22 14:30"
export const formatYYYYMMDD = (date, time) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day} ${time}`;
};

// Input: "2024-10-22"
// Output: - true (jika tanggal saat ini setelah 22 Oktober 2024)
//         - false (jika tanggal saat ini sebelum 22 Oktober 2024)
export const hasDatePassed = (targetDate) => {
  const formattedTargetDate = new Date(targetDate).toISOString().split("T")[0];
  const currentDate = new Date().toISOString().split("T")[0];

  return new Date(formattedTargetDate) < new Date(currentDate);
};

// Input: "2024-10-22T14:30:00Z"
// Output: { date: "Senin, 25 Nov 2024", time: "14.30" }
export const formatISOToDayDateTime = (isoString) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "eeee, dd MMM yyyy", { locale: id });
  const formattedTime = format(date, "HH.mm", { locale: id });

  return {
    date: formattedDate,
    time: formattedTime,
  };
};
