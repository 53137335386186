import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Image,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";

import MascotQuestion from "../../assets/img/mascot-20.png";
import Axios from "../../utils/axiosService";
import CustomToast from "../Toast/CustomToast";
import { ButtonConfirm, ButtonPrimary, ButtonSecondary } from "../button";
import axios from "axios";

const ModalConfirmRoom = ({ data, setUpcomingData, refreshData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen: isOpenModalConfirm,
    onOpen: onOpenModalConfirm,
    onClose: onCloseModalConfirm,
  } = useDisclosure();
  const toast = useToast();

  const handleConfirmBooking = async () => {
    setIsLoading(true);
    try {
      const { data: data_response } = await Axios.patch(
        `/room/booking/${data.booking_id}`,
        {
          status: "Booking Confirmed",
          occurance_id: data.id,
        }
      );

      await axios.post(
        process.env.REACT_APP_N8N_WEBHOOK_BOOKING_ROOM_CONFIRM_CANCEL,
        {
          action: "confirm",
          occurance_id: data.id,
        }
      );

      if (!data_response.errorStatus) {
        // errorStatus === false --> patch booking confirm success
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Success"
              description="Success Update Status"
              status="success"
            />
          ),
          duration: 5000,
          isClosable: true,
        });

        const updatedBookingData = data_response.data.data[1][0];
        setUpcomingData((prevState) => {
          const newData = prevState.map((prevData) => {
            return prevData.id === updatedBookingData.id
              ? { ...prevData, booking_status: updatedBookingData.status }
              : prevData;
          });

          return newData;
        });
      }
      if (refreshData) {
        refreshData();
      }
      onCloseModalConfirm();
      setIsLoading(false);
    } catch (error) {
      // cek url error apakah dari BE patch /room/booking/:id atau N8N inactive
      if (error.config?.url?.includes(`/room/booking/${data.booking_id}`)) {
        // Error dari PATCH data booking occurrence status
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Failed to Confirm"
              description="This booking has been canceled."
              status="error"
            />
          ),
          duration: 5000,
          isClosable: true,
        });
        // update status upcomingData
        // code ini seharusnya diganti dengan refetch upcomingData
        setUpcomingData((prevState) =>
          prevState.map((prevData) =>
            prevData.id === data.id
              ? { ...prevData, booking_status: "Booking Cancelled" }
              : prevData
          )
        );
      } else if (
        error.config?.url ===
        process.env.REACT_APP_N8N_WEBHOOK_BOOKING_ROOM_CONFIRM_CANCEL
      ) {
        // Error dari POST Webhook N8N (possibly webhook sedang tidak aktif)
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Success"
              description="Success to confirm booking, but failed to send email notification due webhooks issue"
              status="warning"
            />
          ),
          duration: 5000,
          isClosable: true,
        });
        // update status upcomingData
        // code ini seharusnya diganti dengan refetch upcoming data
        setUpcomingData((prevState) =>
          prevState.map((prevData) =>
            prevData.id === data.id
              ? { ...prevData, booking_status: "Booking Confirmed" }
              : prevData
          )
        );
      }

      // confirm from booking detail, check update data with refreshData
      if (refreshData) {
        refreshData();
      }
      onCloseModalConfirm();
      setIsLoading(false);
      return;
    }
  };

  return (
    <>
      <ButtonConfirm
        onClick={(e) => {
          e.stopPropagation();
          onOpenModalConfirm();
        }}
      />
      <Modal
        isOpen={isOpenModalConfirm}
        onClose={onCloseModalConfirm}
        isCentered
        w="100%"
      >
        <ModalOverlay w="100%" h="100%" />
        <ModalContent bg="white" borderRadius="12px" padding="16px" w="80%">
          <ModalBody padding="0">
            <Flex
              direction="column"
              gap="24px"
              width="100%"
              bg="#FFFFFF"
              borderRadius="12px"
            >
              <Flex direction="column" gap="16px">
                <Flex justifyContent="center">
                  <Image
                    justifyContent="center"
                    src={MascotQuestion}
                    w="86px"
                    h="86px"
                  />
                </Flex>
                <Flex direction="column" gap="12px" alignItems="center">
                  <Text fontSize="14px" fontWeight="500">
                    Konfirmasi kehadiran?
                  </Text>
                  <Text fontSize="14px" fontWeight="400" color="#5C5C5C">
                    Pastikan kamu sudah berada di dalam ruangan meeting terlebih
                    dahulu
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="12px" width="100%" justifyContent="space-between">
                <ButtonSecondary
                  text="Batalkan"
                  handleClick={onCloseModalConfirm}
                />
                <ButtonPrimary
                  text="Konfirmasi"
                  handleClick={handleConfirmBooking}
                  isLoading={isLoading}
                />
              </Flex>
              <ModalCloseButton borderRadius="10px" />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalConfirmRoom;
