import React from "react";
import { Button } from "@chakra-ui/react";
import { ACTIVE_BUTTON_COLOR } from "../../utils/constant";

const ButtonPrimary = ({
  text,
  handleClick,
  isLoading,
  color = ACTIVE_BUTTON_COLOR,
}) => {
  return (
    <Button
      fontSize="12px"
      fontWeight="500"
      padding="6px 12px"
      color="white"
      bgColor={color}
      borderRadius="100px"
      border="1px"
      borderColor={color}
      gap="8px"
      direction="row"
      width="100%"
      height="32px"
      alignItems="center"
      isLoading={isLoading}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default ButtonPrimary;
