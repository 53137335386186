import {
  Box,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

const TimeInputCustom = ({ handleChange, filter, type }) => {
  const [time, setTime] = useState(
    type === "start" ? filter.start : filter.end || "00:00"
  );
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handleTimeSelect = (selectedTime) => {
    setTime(selectedTime);
    setPopoverOpen(false); // Tutup popover setelah memilih waktu
    handleChange({ type, selectedTime }); // Panggil handleChange dengan waktu yang baru
  };

  const generateTimes = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      ["00", "15", "30", "45"].forEach((minute) => {
        const formattedTime = `${hour.toString().padStart(2, "0")}:${minute}`;
        times.push(formattedTime);
      });
    }
    return times;
  };

  return (
    <Box position="relative" width="100%">
      {/* Input yang digunakan sebagai trigger */}
      <Popover isOpen={isPopoverOpen} onClose={() => setPopoverOpen(false)}>
        <PopoverTrigger>
          <Input
            value={time}
            readOnly
            onClick={() => setPopoverOpen(!isPopoverOpen)}
            width="100%"
            size="xs"
            fontSize="12px"
            borderRadius="6px"
            cursor="pointer"
          />
        </PopoverTrigger>
        <PopoverContent
          width="150px"
          maxHeight="200px"
          overflowY="auto"
          zIndex="10"
        >
          <PopoverBody>
            <VStack spacing={1} align="stretch">
              {generateTimes().map((timeOption) => (
                <Text
                  key={timeOption}
                  padding="4px 8px"
                  borderRadius="4px"
                  _hover={{ backgroundColor: "gray.100", cursor: "pointer" }}
                  onClick={() => handleTimeSelect(timeOption)}
                >
                  {timeOption}
                </Text>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default TimeInputCustom;
