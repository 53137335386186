import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import "moment-timezone";
import "moment/locale/id";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";

import styles from "./filter.module.css";
import Axios from "../../utils/axiosService";
import { SearchIcon } from "@chakra-ui/icons";
import TimeInputCustom from "../input/time/TimeInputCustom";

function Filter({
  setIsLoading,
  filter,
  setFilter,
  handleSubmit,
  getAllRoomData,
  emptyFilter,
}) {
  moment.locale("id");
  const [areaData, setAreaData] = useState([]);
  const dateMin = moment(new Date()).format("yyyy-MM-DD");
  const dateMax = moment(new Date()).add(31, "days").format("yyyy-MM-DD");
  const allowedRoles = ["superadmin", "superuser"];

  const [userDetail, setUserDetail] = useState();

  const fetchUser = () => {
    let userData = JSON.parse(localStorage.getItem("user_data"));
    Axios({
      method: "GET",
      url: `/admin?user_id=${userData?.user_id}${
        userData?.user_email && `&user_email=${userData?.user_email}`
      }`,
    }).then((result) => {
      if (result.status === 200) {
        setUserDetail({ ...result.data.data.data[0] });
      }
    });
  };
  useEffect(() => {
    fetchUser();
    Axios({
      method: "GET",
      url: "/area",
    }).then((res) =>
      setAreaData(
        res.data.data.data.sort(function (a, b) {
          return a.id - b.id;
        })
      )
    );
  }, []);

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleChangeTime = (e) => {
    setFilter({ ...filter, [e.type]: e.selectedTime });
  };

  const resetFilter = () => {
    setIsLoading(true);
    getAllRoomData();
    setFilter(emptyFilter);
    localStorage.setItem("filterRoom", JSON.stringify(emptyFilter));
    setIsLoading(false);
  };

  return (
    <Box as="form" className={styles["filter-container"]}>
      <Flex fontSize="12px" gap="0.8em">
        <Flex w="55%" flexDir="column" gap="0.9em">
          <FormControl display="flex">
            <Text htmlFor="location" className={styles["text-labels"]}>
              Location
            </Text>
            <Select
              id="location"
              name="location"
              value={filter.location}
              onChange={handleChange}
              width="calc(100% - 65px)"
              size="xs"
              fontSize="12px"
              borderRadius="6px"
              defaultValue={""}
            >
              <option value="" disabled>
                Select office
              </option>
              {areaData.map((area, index) => {
                return (
                  <option key={index} value={area.id}>
                    {area.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl display="flex">
            <Text htmlFor="type" className={styles["text-labels"]}>
              Type
            </Text>
            <Select
              id="type"
              name="type"
              value={filter.type}
              onChange={handleChange}
              width="calc(100% - 65px)"
              size="xs"
              fontSize="12px"
              borderRadius="6px"
              defaultValue={""}
            >
              <option value="" disabled>
                Select type
              </option>
              <option value={"ruang meeting"}>Ruang Meeting</option>
              <option value={"aula"}>Aula</option>
            </Select>
          </FormControl>
          <FormControl display="flex">
            <Text htmlFor="capacity" className={styles["text-labels"]}>
              Capacity
            </Text>
            <Input
              name="capacity"
              onChange={handleChange}
              value={filter.capacity}
              width="calc(100% - 65px)"
              size="xs"
              fontSize="12px"
              borderRadius="6px"
              type="tel"
            />
          </FormControl>
        </Flex>
        <Flex w="45%" flexDir="column" gap="0.9em">
          <FormControl display="flex">
            <Text htmlFor="date" className={styles["text-labels"]}>
              Date
            </Text>
            <Input
              id="date"
              type="date"
              name="date"
              width="100%"
              size="xs"
              fontSize="12px"
              borderRadius="6px"
              onChange={handleChange}
              value={filter.date}
              min={dateMin}
              max={
                allowedRoles.includes(userDetail?.role?.toLowerCase())
                  ? null
                  : dateMax
              }
            />
          </FormControl>
          <FormControl display="flex">
            <Text htmlFor="start" className={styles["text-labels"]}>
              Start
            </Text>
            <TimeInputCustom
              handleChange={handleChangeTime}
              filter={filter}
              type="start"
            />
            {/* <Input
              width="100%"
              size="xs"
              fontSize="12px"
              borderRadius="6px"
              type="time"
              id="start"
              name="start"
              onChange={handleChange}
              value={filter.start}
              min="00:00"
            /> */}
          </FormControl>
          <FormControl display="flex">
            <Text htmlFor="end" className={styles["text-labels"]}>
              End
            </Text>
            <TimeInputCustom
              handleChange={handleChangeTime}
              filter={filter}
              type="end"
            />
            {/* <Input
              width="100%"
              size="xs"
              fontSize="12px"
              borderRadius="6px"
              type="time"
              id="end"
              name="end"
              onChange={handleChange}
              value={filter.end}
              max="23:59"
            /> */}
          </FormControl>
        </Flex>
      </Flex>
      <Text
        fontSize="12px"
        fontWeight="500"
        color="rgba(0, 0, 0, 0.7)"
        textAlign="center"
        my="16px"
      >
        Date: {filter.date.split("-").reverse().join("-") ?? "-"},{" "}
        {filter.start ?? "-"} - {filter.end ?? "-"}
      </Text>
      <Flex fontSize="12px" gap="7px" py="4px">
        <Flex w="100%" flexDir="column" gap="0.8em">
          <FormControl display="flex">
            <Text
              htmlFor="end"
              className={styles["text-labels"]}
              marginRight="10px"
            >
              Room
            </Text>
            <InputGroup>
              <Input
                name="roomName"
                onChange={handleChange}
                value={filter.roomName}
                placeholder="Search room by name"
                size="xs"
                fontSize="12px"
                borderRadius="6px"
                type="text"
              />
              <InputRightElement style={{ height: "100%" }}>
                <SearchIcon color="gray.300" style={{ height: "55%" }} />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
      </Flex>
      <Flex
        mt="12px"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        color="rgba(0, 0, 0, 0.7)"
      >
        <Text fontWeight="600" fontSize="18px">
          List Room
        </Text>
        <Flex gap="8px" alignItems="center">
          <Button
            onClick={() => resetFilter()}
            fontWeight="500"
            padding="0"
            fontSize="13px"
            background="none"
            border="none"
            variant="none"
          >
            Reset Filter
          </Button>
          <Button
            background="rgba(0, 111, 149, 1)"
            color="#FFF"
            border="none"
            borderRadius="6px"
            fontSize="14px"
            onClick={() => handleSubmit()}
            size="sm"
          >
            Filter
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Filter;
