import React from "react";
import { Flex, Box, Text, Skeleton, Image } from "@chakra-ui/react";
import styles from "../bookingdetail.module.css";

const SectionRoomData = ({ bookingRoomData }) => {
  return (
    <div>
      <Flex>
        <Text className={styles["section-title"]}>Room Data</Text>
      </Flex>
      <Flex>
        <Box className={styles["schedule-card"]}>
          <Flex className={styles["schedule-sub-section"]}>
            <Box width={"45%"} className={styles["room-detail-image"]}>
              <Skeleton
                isLoaded={bookingRoomData?.booking_room_master_datum}
                height="100%"
              >
                <Image
                  src={
                    bookingRoomData?.booking_room_master_datum?.image
                      ? bookingRoomData?.booking_room_master_datum?.image
                      : "https://img.freepik.com/premium-photo/front-view-blank-white-poster-black-frame-light-wall-modern-meeting-room-with-empty-wooden-table-surrounded-by-black-white-chairs-city-view-from-big-window-3d-rendering-mock-up_688884-70.jpg"
                  }
                  alt="meeting-room"
                  height="50%"
                />
              </Skeleton>
            </Box>
            <Box width={"55%"} className={styles["room-detail-data"]}>
              <Box className={styles["room-detail-info-card"]}>
                <Text className={styles["text-room-name"]}>
                  {bookingRoomData?.booking_room_master_datum?.name}
                </Text>
              </Box>
              <Box className={styles["room-detail-info-card"]}>
                <Text className={styles["text-room-location"]}>
                  {bookingRoomData?.booking_room_master_datum?.office_area.name}
                </Text>
              </Box>
              <Box>
                <Text className={styles["text-room-variable"]}>
                  Capacity :{" "}
                  {bookingRoomData?.booking_room_master_datum?.capacity} person
                </Text>
                <Text className={styles["text-room-variable"]}>
                  Type : {bookingRoomData?.booking_room_master_datum?.type}
                </Text>
              </Box>
            </Box>
          </Flex>
          <Flex className={styles["schedule-sub-section"]}>
            <Box className={styles["room-detail-info-card"]}>
              <Text className={styles["text-room-variable"]} mb="0.5em">
                Description:
              </Text>
              <Text className={styles["text-room-data"]}>
                {bookingRoomData?.booking_room_master_datum?.location_detail}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default SectionRoomData;
