import React, { useEffect, useState } from "react";

import { CircularProgress, Flex } from "@chakra-ui/react";

import { FetchDriverRequest } from "../../../../api/fetchDriverRequest";

import Header from "../../../../components/BookingCar/Header/MainHeader";
import BookingForm from "../../../../components/BookingCar/MainCar/BookingForm";
import CarList from "../../../../components/BookingCar/MainCar/CarList";
import NotRegistered from "../../../../components/BookingCar/MainCar/NotRegistered";
import Registered from "../../../../components/BookingCar/MainCar/Registered";
import Inactive from "../../../../components/BookingCar/MainCar/Inactive";
import NotUsable from "../../../../components/BookingCar/MainDriver/NotUsable";
import History from "../../../../components/carousels/History";
import Upcoming from "../../../../components/carousels/Upcoming";
import Contact from "../../../../components/CTA/Contact";

function BookingCarView() {
  const [activeTab, setActiveTab] = useState("car");
  const [carUserStatus, setCarUserStatus] = useState();

  const [isLoadingFetchRequest, setIsLoadingFetchRequest] = useState(true);
  useEffect(() => {
    const fetchDriverRequestData = async () => {
      const { driverRequest, loading } = await FetchDriverRequest();
      if (driverRequest.active === false) {
        setCarUserStatus("inactive");
      } else {
        setCarUserStatus(driverRequest.verification_status);
      }
      setIsLoadingFetchRequest(loading);
    };

    fetchDriverRequestData();
  }, []);

  return (
    <Flex
      w="100%"
      bg="white"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Header
        backLink={"/"}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === "car" && (
        <>
          {isLoadingFetchRequest ? (
            <Flex
              width="100%"
              height="400px"
              justifyContent="center"
              alignItems="center"
              borderBottom="1px solid #EFEFEF"
            >
              <CircularProgress isIndeterminate color="#3182CE" />
            </Flex>
          ) : carUserStatus === "accepted" ? (
            <>
              <BookingForm isBookingCar={false} />
              <CarList />
            </>
          ) : carUserStatus === "requested" ? (
            <Registered />
          ) : carUserStatus === "inactive" ? (
            <Inactive />
          ) : (
            <NotRegistered status={carUserStatus} />
          )}
          <Flex
            width="100%"
            direction="column"
            rowGap="24px"
            marginTop="24px"
            marginBottom="48px"
          >
            <Upcoming type="car" />
            <History type="car" />
            <Contact type="car" />
          </Flex>
        </>
      )}
      {activeTab === "driver" && <NotUsable />}
    </Flex>
  );
}

export default BookingCarView;
