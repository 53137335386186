import React from "react";
import { Button } from "@chakra-ui/react";
import { ACTIVE_BUTTON_COLOR } from "../../utils/constant";

const ButtonConfirm = ({ onClick }) => {
  return (
    <Button
      size="xs"
      bg={ACTIVE_BUTTON_COLOR}
      color="white"
      borderRadius="99px"
      p="0 16px"
      fontSize="12px"
      fontWeight="400"
      width="100%"
      _hover={{ bg: "#1A77CC" }}
      _active={{ bg: "#174D87" }}
      onClick={onClick}
    >
      Confirm
    </Button>
  );
};

export default ButtonConfirm;
