import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import styles from "../bookingdetail.module.css";
import { SectionRequestDataAula } from "./booking-request-data";

const SectionBookingRequestData = ({ bookingRoomData }) => {
  return (
    <div>
      <Flex>
        <Text className={styles["section-title"]}>
          {bookingRoomData?.booking_room_master_datum?.type === "Aula"
            ? "Booking & Aula Request Data"
            : "Booking Request Data"}
        </Text>
      </Flex>
      <Flex>
        <Box className={styles["schedule-card"]}>
          <Flex>
            <Box width={"35%"}>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-variable"]}>Purpose</Text>
              </Flex>
            </Box>
            <Box width={"65%"}>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-value"]}>
                  : {bookingRoomData?.booking_purpose}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex>
            <Box width={"35%"}>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-variable"]}>Attendees</Text>
              </Flex>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-variable"]}>Period</Text>
              </Flex>
              {bookingRoomData?.is_repeat && (
                <Flex className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-variable"]}>Period Repeat</Text>
                </Flex>
              )}
            </Box>
            <Box width={"65%"}>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-value"]}>
                  : {bookingRoomData?.attendees_amount} person
                </Text>
              </Flex>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-value"]}>
                  : {bookingRoomData?.period_repeat}
                </Text>
              </Flex>
              {bookingRoomData?.is_repeat && (
                <Flex className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-value"]}>
                    : {bookingRoomData?.count_repeat}x
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
          {bookingRoomData?.booking_room_master_datum?.type === "Aula" && (
            <SectionRequestDataAula bookingRoomData={bookingRoomData} />
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default SectionBookingRequestData;
