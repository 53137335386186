import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import styles from "./../../bookingdetail.module.css";

const SectionRequestDataAula = ({ bookingRoomData }) => {
  return (
    <>
      <Flex className={styles["sub-section-container"]}>
        <Box>
          <Text className={styles["sub-section-title"]}>
            Layout Information
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Box width={"40%"}>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-variable"]}>Layout</Text>
          </Flex>
          {bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "uShape" ||
          bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "classroom" ? (
            <>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-variable"]}>Seat Option</Text>
              </Flex>
              {bookingRoomData?.booking_room_booking_aula?.seat_option ===
              "kursiKampus" ? (
                <Flex className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-variable"]}>Kursi Kampus</Text>
                </Flex>
              ) : bookingRoomData?.booking_room_booking_aula?.seat_option ===
                "kursiStandard" ? (
                <Flex className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-variable"]}>
                    Standard Chair
                  </Text>
                </Flex>
              ) : null}
            </>
          ) : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "roundTable" ? (
            <>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-variable"]}>Table(s)</Text>
              </Flex>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-variable"]}>
                  Chair(s) per Table
                </Text>
              </Flex>
            </>
          ) : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "lesehan" ? (
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>Lesehan Area</Text>
            </Flex>
          ) : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "others" ? (
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-variable"]}>Layout Detail</Text>
            </Flex>
          ) : null}
        </Box>
        <Box width={"60%"}>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-value"]}>
              :{" "}
              {bookingRoomData?.booking_room_booking_aula?.aula_layout ===
              "lesehan"
                ? "Lesehan"
                : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
                  "uShape"
                ? "U Shape"
                : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
                  "classroom"
                ? "Classroom"
                : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
                  "roundTable"
                ? "Round Table"
                : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
                  "others"
                ? "Others"
                : null}
            </Text>
          </Flex>
          {bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "uShape" ||
          bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "classroom" ? (
            <>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-value"]}>
                  :{" "}
                  {bookingRoomData?.booking_room_booking_aula?.seat_option ===
                  "kursiKampus"
                    ? "Kursi Kampus"
                    : bookingRoomData?.booking_room_booking_aula
                        ?.seat_option === "kursiStandard"
                    ? "Kursi Standard"
                    : null}
                </Text>
              </Flex>
              {bookingRoomData?.booking_room_booking_aula?.seat_option ===
              "kursiKampus" ? (
                <Flex className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-value"]}>
                    :{" "}
                    {
                      bookingRoomData?.booking_room_booking_aula
                        ?.kursi_kampus_amount
                    }{" "}
                    pcs
                  </Text>
                </Flex>
              ) : bookingRoomData?.booking_room_booking_aula?.seat_option ===
                "kursiStandard" ? (
                <Flex className={styles["room-detail-info-card"]}>
                  <Text className={styles["text-value"]}>
                    :{" "}
                    {
                      bookingRoomData?.booking_room_booking_aula
                        ?.kursi_standard_amount
                    }{" "}
                    pcs
                  </Text>
                </Flex>
              ) : null}
            </>
          ) : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "roundTable" ? (
            <>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-value"]}>
                  : {bookingRoomData?.booking_room_booking_aula?.table_amount}{" "}
                  pcs
                </Text>
              </Flex>
              <Flex className={styles["room-detail-info-card"]}>
                <Text className={styles["text-value"]}>
                  :{" "}
                  {
                    bookingRoomData?.booking_room_booking_aula
                      ?.chair_per_table_amount
                  }{" "}
                  pcs
                </Text>
              </Flex>
            </>
          ) : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "lesehan" ? (
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>
                : {bookingRoomData?.booking_room_booking_aula?.lesehan_area}
              </Text>
            </Flex>
          ) : bookingRoomData?.booking_room_booking_aula?.aula_layout ===
            "others" ? (
            <Flex className={styles["room-detail-info-card"]}>
              <Text className={styles["text-value"]}>
                :{" "}
                {bookingRoomData?.booking_room_booking_aula?.detail_aula_layout}
              </Text>
            </Flex>
          ) : null}
        </Box>
      </Flex>
      <Flex className={styles["sub-section-container"]}>
        <Box>
          <Text className={styles["sub-section-title"]}>
            Supporting Facilities
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Box width={"40%"}>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-variable"]}>Flipchart</Text>
          </Flex>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-variable"]}>Whiteboard</Text>
          </Flex>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-variable"]}>Square Table</Text>
          </Flex>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-variable"]}>Cable Roll</Text>
          </Flex>
        </Box>
        <Box width={"60%"}>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-value"]}>
              : {bookingRoomData?.booking_room_booking_aula?.flipchart_amount}{" "}
              pcs
            </Text>
          </Flex>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-value"]}>
              : {bookingRoomData?.booking_room_booking_aula?.whiteboard_amount}{" "}
              pcs
            </Text>
          </Flex>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-value"]}>
              :{" "}
              {bookingRoomData?.booking_room_booking_aula?.square_table_amount}{" "}
              pcs
            </Text>
          </Flex>
          <Flex className={styles["room-detail-info-card"]}>
            <Text className={styles["text-value"]}>
              : {bookingRoomData?.booking_room_booking_aula?.cable_roll_amount}{" "}
              pcs
            </Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default SectionRequestDataAula;
