import React from "react";
import { Button } from "@chakra-ui/react";

const ButtonCancel = ({ onClick }) => {
  return (
    <Button
      size="xs"
      border="1px solid #E6001C"
      p="0 16px"
      width="100%"
      bgColor="white"
      borderRadius="99px"
      color="#E6001C"
      fontSize="12px"
      fontWeight="400"
      _hover={{ bg: "#FFE5E5" }}
      _active={{ bg: "#FFCCCC" }}
      onClick={onClick}
    >
      Cancel
    </Button>
  );
};

export default ButtonCancel;
