import React from "react";
import { Button } from "@chakra-ui/react";
import { BG_COLOR_CANCELLED, TEXT_COLOR_CANCELLED } from "../../utils/constant";

const ButtonCancelled = () => {
  return (
    <Button
      cursor={"not-allowed"}
      size="xs"
      bg={BG_COLOR_CANCELLED}
      color={TEXT_COLOR_CANCELLED}
      borderRadius="99px"
      p="0 16px"
      fontSize="12px"
      fontWeight="400"
      width="100%"
    >
      Dibatalkan
    </Button>
  );
};

export default ButtonCancelled;
