import React from "react";
import { Skeleton, Box, Flex, Text, Image } from "@chakra-ui/react";
import styles from "../roomdetail.module.css";
import ImageCarousel from "./carousel";

const SectionRoomInfoData = ({
  roomData,
  img,
  roomAndFacilitiesImg,
  facilityData,
}) => {
  return (
    <>
      <Box>
        <Text className={styles["text-title"]}>{roomData?.name}</Text>
      </Box>
      <Box className={styles["image-styling"]}>
        <Skeleton
          isLoaded={roomData && (roomAndFacilitiesImg || img)}
          height="100%"
          width="100%"
        >
          {roomAndFacilitiesImg ? (
            <ImageCarousel imageData={roomAndFacilitiesImg} />
          ) : (
            <Image marginBottom="1.25em" src={img} alt="meeting-room" />
          )}
        </Skeleton>
      </Box>
      <Box>
        <Flex className={styles["room-detail-info"]}>
          <Box width={"40%"}>
            <Flex>
              <Text className={styles["text-variable"]}>Location:</Text>
            </Flex>
          </Box>
          <Box width={"60%"}>
            <Flex>
              <Text className={styles["text-value"]}>
                {roomData.office_area ? roomData.office_area.name : null}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex className={styles["room-detail-info"]}>
          <Box width={"40%"}>
            <Flex>
              <Text className={styles["text-variable"]}>Details:</Text>
            </Flex>
          </Box>
          <Box width={"60%"}>
            <Flex>
              <Text className={styles["text-value"]}>
                {roomData?.location_detail}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex className={styles["room-detail-info"]}>
          <Box width={"40%"}>
            <Flex>
              <Text className={styles["text-variable"]}>Type:</Text>
            </Flex>
          </Box>
          <Box width={"60%"}>
            <Flex>
              <Text className={styles["text-value"]}>{roomData?.type}</Text>
            </Flex>
          </Box>
        </Flex>
        <Flex className={styles["room-detail-info"]}>
          <Box width={"40%"}>
            <Flex>
              <Text className={styles["text-variable"]}>Capacity:</Text>
            </Flex>
          </Box>
          <Box width={"60%"}>
            <Flex>
              <Text className={styles["text-value"]}>{roomData?.capacity}</Text>
            </Flex>
          </Box>
        </Flex>
        <Flex marginBottom={"1.75em"}>
          <Box width={"40%"}>
            <Flex>
              <Text className={styles["text-variable"]}>
                {facilityData.length > 1 ? "Facilities:" : "Facility:"}
              </Text>
            </Flex>
          </Box>
          <Box width={"60%"}>
            {facilityData?.map((facility, index) => (
              <Flex>
                <Text className={styles["text-value"]} key={index}>
                  - {facility.item_name}
                </Text>
              </Flex>
            ))}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default SectionRoomInfoData;
