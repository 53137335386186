import React from "react";
import { Flex, Box, Center, Text } from "@chakra-ui/react";
import styles from "../bookingdetail.module.css";

const SectionBookingListStatus = ({ bookingRoomData }) => {
  return (
    <Flex>
      <Box className={styles["schedule-card"]}>
        <Center className={styles["schedule-sub-section-status"]}>
          {bookingRoomData?.status !== "Booking Cancelled" ? (
            <Box>
              <Text className={styles["text-status-title"]}>
                Booking Completed
              </Text>
              <Text className={styles["text-status-subtitle"]}>
                Thank you for using Booking Room!
              </Text>
            </Box>
          ) : (
            <Box>
              <Text className={styles["text-status-title"]}>
                {bookingRoomData.status}
              </Text>
              <Text className={styles["text-status-subtitle"]}>
                {bookingRoomData?.status === "Booking Created"
                  ? "Booking has been successfully created"
                  : bookingRoomData?.status === "Booking Cancelled"
                  ? "Your booking has been cancelled"
                  : null}
              </Text>
            </Box>
          )}
        </Center>
      </Box>
    </Flex>
  );
};

export default SectionBookingListStatus;
