import { useState, useEffect } from "react";
import { Text, Flex, Box, CircularProgress } from "@chakra-ui/react";

import { FetchUpcoming } from "../../../../../api/fetchUpcoming";
import { Card } from "./Card";

function Upcoming() {
  const [upcomingData, setUpcomingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUpcomingData = async ({ type }) => {
      const { upcomingData, loading } = await FetchUpcoming({ type });
      setUpcomingData(upcomingData);
      setIsLoading(loading);
    };
    fetchUpcomingData({ type: "all" });
  }, []);

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px 0 80px 0"
      flexDirection="column"
      minHeight="calc(100vh - 72px)"
    >
      {isLoading ? (
        <Flex
          width="100%"
          height="calc(100vh - 72px)"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress isIndeterminate color="#3182CE" />
        </Flex>
      ) : upcomingData.length === 0 ? (
        <Box padding="0 16px">
          <Box
            w="100%"
            padding="16px 8px 16px 8px"
            borderRadius="12px"
            bg="#EFEFEF"
          >
            <Text fontSize="14px" fontWeight="400" color="#5E5E5E">
              Kamu belum memiliki rencana booking
            </Text>
          </Box>
        </Box>
      ) : (
        <Flex
          w="100%"
          direction="column"
          rowGap="16px"
          overflowX="hidden"
          overflowY="scroll"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {upcomingData.map((upcoming, i) => (
            <Card key={i} type="upcoming" data={upcoming} />
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default Upcoming;
