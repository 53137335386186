import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import "moment-timezone";
import "moment/locale/id";
import { Flex, Box } from "@chakra-ui/react";
import MainHeader from "../../../../components/BookingRoom/Header/MainHeader";
import styles from "./bookingdetail.module.css";
import { useParams } from "react-router-dom";
import Axios from "../../../../utils/axiosService";
import {
  ButtonCancelled,
  ButtonConfirmed,
} from "../../../../components/button";
import { AlignmentButton } from "../../../../components/layout/booking-room";
import {
  ModalCancelRoom,
  ModalConfirmRoom,
} from "../../../../components/modal";
import {
  SectionBookingListStatus,
  SectionRoomData,
  SectionBookingRequestData,
  SectionBookingSchedule,
} from "./modules";

function BookingDetail() {
  moment.locale("en");

  const params = useParams();
  const [bookingId] = useState(params.bookingId);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingRoomData, setBookingRoomData] = useState([]);
  const [bookingOccurrenceData, setBookingOccurrenceData] = useState([]);

  const fetchBookingData = () => {
    setIsLoading(true);
    Axios({
      method: "GET",
      url: `/room/booking/${bookingId}`,
    }).then((result) => {
      if (result.data.data.status === "OK") {
        let bookingData = result.data.data.data;

        const roomName = bookingData.booking_room_master_datum.name;
        const areaName = bookingData.booking_room_master_datum.office_area.name;
        let bookingOccurrence = bookingData.booking_room_booking_occurances;

        // Menambahkan booking_details ke setiap objek
        bookingOccurrence = bookingOccurrence.map((occurrence) => ({
          ...occurrence,
          booking_status: occurrence.status,
          booking_details: {
            room_name: roomName,
            area_name: areaName,
            booking_purpose: bookingData.booking_purpose,
          },
        }));

        let sortedBookingOccurrence = bookingOccurrence.sort(function (a, b) {
          return a.id - b.id;
        });

        setBookingRoomData(bookingData);
        setBookingOccurrenceData(sortedBookingOccurrence);
        setIsLoading(false);
      }
    });
  };

  const renderActionbuttonBookingRoom = (occurrence) => {
    const startUsageTime = new Date(occurrence.start_usage);
    const currentTime = new Date();
    const showConfirm = currentTime >= startUsageTime;

    switch (occurrence.booking_status) {
      case "Booking Created":
        return (
          <AlignmentButton>
            <ModalCancelRoom
              data={occurrence}
              setUpcomingData={setBookingOccurrenceData}
            />
            {showConfirm && (
              <ModalConfirmRoom
                data={{
                  booking_id: occurrence.booking_id,
                  id: occurrence.id,
                }}
                setUpcomingData={setBookingOccurrenceData}
                refreshData={fetchBookingData}
              />
            )}
          </AlignmentButton>
        );
      case "Booking Confirmed":
        return (
          <AlignmentButton>
            <ButtonConfirmed />
          </AlignmentButton>
        );
      case "Booking Cancelled":
        return (
          <AlignmentButton>
            <ButtonCancelled />
          </AlignmentButton>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchBookingData();
    // eslint-disable-next-line
  }, [bookingId]);

  return (
    <>
      <Flex className={styles["header-container"]} zIndex={9}>
        <MainHeader title="Booking Detail" backLink="/history" />
      </Flex>
      <Flex className={styles["main-container"]}>
        <Box>
          <SectionBookingListStatus bookingRoomData={bookingRoomData} />
          <SectionRoomData bookingRoomData={bookingRoomData} />
          <SectionBookingRequestData bookingRoomData={bookingRoomData} />
          <SectionBookingSchedule
            bookingOccurrenceData={bookingOccurrenceData}
            bookingRoomData={bookingRoomData}
            isLoading={isLoading}
            renderActionbuttonBookingRoom={renderActionbuttonBookingRoom}
          />
        </Box>
      </Flex>
    </>
  );
}

export default BookingDetail;
