import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Flex, Text } from "@chakra-ui/react";
import {
  MdCircle,
  MdDirectionsCar,
  MdHouse,
  MdMeetingRoom,
} from "react-icons/md";

import {
  COLOR_BLUE_PRIMARY,
  COLOR_ERROR,
  COLOR_SUCCESS,
  COLOR_WARNING,
} from "../../utils/constant";
import { formatISOtoDDMMMYYYY } from "../../utils/formatDate";
import { ModalConfirmRoom, ModalCancelRoom } from "../modal";

import { ButtonCancelled, ButtonConfirmed } from "../button";
import { AlignmentButton } from "../layout/booking-room";

const mapBookingDetails = (booking_type, id) => {
  let icon, title, detailLink;
  switch (booking_type) {
    case "Operasional":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Operasional";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "Perjadin":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Perjalanan Dinas";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "Driver":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Driver";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "Room":
      icon = <MdMeetingRoom size="24px" color="#222222" />;
      title = "Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
    case "mess":
      icon = <MdHouse size="24px" color="#222222" />;
      title = "Booking Mes";
      detailLink = `/booking-mess/booking-detail/${id}`;
      break;
    case "room":
      icon = <MdMeetingRoom size="24px" color="#222222" />;
      title = "Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
    case "car":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Car";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    default:
      icon = <MdCircle size="24px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
  }
  return { icon, title, detailLink };
};

const CardCarousel = ({ type, data, setUpcomingData }) => {
  const navigate = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  let status = { label: "", color: "" };

  const mappingStatus = {
    "In Progress": "Digunakan",
    Done: "Selesai",
    "Booking Cancelled": "Dibatalkan",
    Rejected: "Ditolak",
    Approved: "Disetujui",
    "Booking Created": "Diproses",
  };

  const mappingColorStatus = {
    "In Progress": COLOR_BLUE_PRIMARY,
    Done: COLOR_SUCCESS,
    "Booking Cancelled": COLOR_ERROR,
    Rejected: COLOR_ERROR,
    Approved: COLOR_SUCCESS,
    "Booking Created": COLOR_WARNING,
  };

  if (type === "history") {
    status.label = mappingStatus[data?.booking_status || "In Progress"];
    status.color = mappingColorStatus[data?.booking_status || "In Progress"];
  } else if (type === "upcoming" && data.booking_type === "Driver") {
    status.label = mappingStatus[data?.booking_status || "Booking Created"];
    status.color = mappingColorStatus[data?.booking_status || "Diproses"];
  }

  const { icon, title, detailLink } = mapBookingDetails(
    data.booking_type,
    data.booking_type.toLowerCase() === "room" ? data.booking_id : data.id
  );

  const renderActionbuttonBookingRoom = useMemo(() => {
    if (type !== "upcoming") {
      return null;
    }

    const startUsageTime = new Date(data.start_usage);
    const currentTime = new Date();
    const showConfirm = currentTime >= startUsageTime;

    switch (data.booking_status) {
      case "Booking Created":
        return (
          <AlignmentButton>
            <ModalCancelRoom setUpcomingData={setUpcomingData} data={data} />
            {showConfirm && (
              <ModalConfirmRoom
                data={data}
                setUpcomingData={setUpcomingData}
                showConfirm={showConfirm}
              />
            )}
          </AlignmentButton>
        );
      case "Booking Confirmed":
        return (
          <AlignmentButton>
            <ButtonConfirmed />
          </AlignmentButton>
        );
      case "Booking Cancelled":
        return (
          <AlignmentButton>
            <ButtonCancelled />
          </AlignmentButton>
        );
      default:
        return null;
    }
  }, [data, setUpcomingData, type]);

  return (
    <>
      <Flex
        direction="column"
        padding="8px"
        spacing="0"
        bg="#FFFFFF"
        style={{
          boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
          borderRadius: "12px",
        }}
        rowGap="12px"
        width="100%"
        cursor={"pointer"}
        onClick={() =>
          navigate.push({
            pathname: detailLink,
            state: { backLink: currentPath },
          })
        }
      >
        <Flex
          direction="row"
          borderBottom="1px solid #EFEFEF"
          paddingBottom="8px"
          alignItems="center"
        >
          <Flex direction="row" alignItems="center" columnGap="4px">
            {icon}
            <Text fontSize="12px" fontWeight="400">
              {title}
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" gap="16px">
          <Flex w="70%" alignItems="flex-start" direction="column">
            <Text fontSize="11px" fontWeight="500">
              Tanggal
            </Text>
            <Text fontSize="11px" fontWeight="400" color="#5E5E5E">
              {formatISOtoDDMMMYYYY(data.start_usage).date}
            </Text>
          </Flex>
          <Flex w="30%" alignItems="flex-start" direction="column">
            <Text fontSize="11px" fontWeight="500">
              Jam
            </Text>
            <Text fontSize="11px" fontWeight="400" color="#5E5E5E">
              {formatISOtoDDMMMYYYY(data.start_usage).time}
            </Text>
          </Flex>
        </Flex>
        {data.booking_type.toLowerCase() === "room" ? (
          renderActionbuttonBookingRoom
        ) : (
          <Box height={"26px"} />
        )}
      </Flex>
    </>
  );
};

export default CardCarousel;
