import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Image,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";

import MascotQuestion from "../../assets/img/mascot-20.png";
import { formatISOtoDDMMMYYYY } from "../../utils/formatDate";
import Axios from "../../utils/axiosService";
import CustomToast from "../Toast/CustomToast";
import { ButtonCancel, ButtonPrimary, ButtonSecondary } from "../button";
import axios from "axios";

const ModalCancelRoom = ({ data, setUpcomingData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const {
    isOpen: isOpenModalConfirm,
    onOpen: onOpenModalConfirm,
    onClose: onCloseModalConfirm,
  } = useDisclosure();

  const handleCancelBooking = async () => {
    setIsLoading(true);

    let bodyCancelData = {
      cancel_reason: "Manual Cancel by User",
      cancel_timestamp: new Date(),
      cancelled_by: localStorage.getItem("username"),
      is_cancel: true,
      status: "Booking Cancelled",
    };

    try {
      const { data: data_response } = await Axios.patch(
        `/room/booking/${data.booking_id}/occurrence/${data.id}/cancel`,
        bodyCancelData
      );

      await axios.post(
        process.env.REACT_APP_N8N_WEBHOOK_BOOKING_ROOM_CONFIRM_CANCEL,
        {
          action: "cancel",
          occurance_id: data.id,
        }
      );

      if (!data_response.errorStatus) {
        // errorStatus === false --> patch booking cancel success
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Success"
              description="Success Update Status"
              status="success"
            />
          ),
          duration: 5000,
          isClosable: true,
        });

        const responseCancelLogData = data_response.data.data.updateLog;
        setUpcomingData((prevState) => {
          const newData = prevState.map((prevData) => {
            return prevData.id === responseCancelLogData.occurance_id
              ? {
                  ...prevData,
                  booking_status: responseCancelLogData.status,
                }
              : prevData;
          });
          return newData;
        });
      }
      onCloseModalConfirm();
      setIsLoading(false);
    } catch (error) {
      // cek url error apakah dari BE patch /room/booking/:id atau N8N inactive
      if (error.config?.url?.includes(`/room/booking/${data.booking_id}`)) {
        // Error dari PATCH data booking occurrence status
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Failed to Cancel"
              description="This booking has been canceled."
              status="error"
            />
          ),
          duration: 5000,
          isClosable: true,
        });
        // update status upcomingData
        // code ini seharusnya diganti dengan refetch upcomingData
        setUpcomingData((prevState) =>
          prevState.map((prevData) =>
            prevData.id === data.id
              ? { ...prevData, booking_status: "Booking Cancelled" }
              : prevData
          )
        );
      } else if (
        error.config?.url ===
        process.env.REACT_APP_N8N_WEBHOOK_BOOKING_ROOM_CONFIRM_CANCEL
      ) {
        // Error dari POST Webhook N8N (possibly webhook sedang tidak aktif)
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Success"
              description="Success to cancel booking, but failed to send email notification due webhooks issue"
              status="warning"
            />
          ),
          duration: 5000,
          isClosable: true,
        });
        // update status upcomingData
        // code ini seharusnya diganti dengan refetch upcoming data
        setUpcomingData((prevState) =>
          prevState.map((prevData) =>
            prevData.id === data.id
              ? { ...prevData, booking_status: "Booking Cancelled" }
              : prevData
          )
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <ButtonCancel
        onClick={(e) => {
          e.stopPropagation();
          onOpenModalConfirm();
        }}
      />

      <Modal
        isOpen={isOpenModalConfirm}
        onClose={onCloseModalConfirm}
        isCentered
        w="100%"
      >
        <ModalOverlay w="100%" h="100%" />
        <ModalContent bg="white" borderRadius="12px" padding="16px" w="80%">
          <ModalBody padding="0">
            <Flex
              direction="column"
              gap="24px"
              width="100%"
              bg="#FFFFFF"
              borderRadius="12px"
            >
              <Flex direction="column" gap="16px">
                <Flex justifyContent="center">
                  <Image
                    justifyContent="center"
                    src={MascotQuestion}
                    w="86px"
                    h="86px"
                  />
                </Flex>
                <Flex direction="column" gap="12px" alignItems="flex-start">
                  <Text fontSize="14px" fontWeight="500">
                    Cancel Room dengan informasi:
                  </Text>
                  <Flex
                    direction="column"
                    gap="8px"
                    fontSize="12px"
                    color="#5C5C5C"
                    fontWeight="500"
                  >
                    <Text>Nama Room: {data.booking_details.room_name} </Text>
                    <Text>
                      Tanggal: {formatISOtoDDMMMYYYY(data.start_usage).date}
                    </Text>
                    <Text>
                      Jam: {formatISOtoDDMMMYYYY(data.start_usage).time}{" "}
                    </Text>
                    <Text>
                      Keperluan: {data.booking_details.booking_purpose}
                    </Text>
                  </Flex>
                  <Text fontSize="14px" fontWeight="500">
                    Konfirmasi Cancel?
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="12px" width="100%" justifyContent="space-between">
                <ButtonSecondary
                  text="Batalkan"
                  handleClick={onCloseModalConfirm}
                />
                <ButtonPrimary
                  text="Ya, Cancel Room"
                  handleClick={handleCancelBooking}
                  color="#E6001C"
                  isLoading={isLoading}
                />
              </Flex>
              <ModalCloseButton borderRadius="10px" />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCancelRoom;
