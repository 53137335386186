import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  Flex,
  Box,
  Text,
  Button,
  Image,
  Input,
  RadioGroup,
  Stack,
  Radio,
  Center,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import MainHeader from "../../../../components/BookingRoom/Header/MainHeader";
import styles from "./roomdetail.module.css";
import { Link, useParams } from "react-router-dom";
import EmptySchedule from "../../../../assets/img/empty-room-schedule.svg";
import Axios from "../../../../utils/axiosService";
import defaultMeetingRoom from "../../../../assets/img/defaultMeetingRoom.png";
import { CardScheduleBooked, SectionRoomInfoData } from "./modules";

function RoomDetails() {
  moment.locale("en");

  const params = useParams();
  const dateNow = moment(new Date()).format("dddd, DD MMMM YYYY");
  const stdDateNow = moment(new Date()).format("YYYY-MM-DD");
  const dateTomorrow = moment(new Date())
    .add(1, "days")
    .format("dddd, DD MMMM YYYY");
  const stdDateTomorrow = moment(new Date())
    .add(1, "days")
    .format("YYYY-MM-DD");
  const dateMin = moment(new Date()).format("yyyy-MM-DD");
  const dateMax = moment(new Date()).add(31, "days").format("yyyy-MM-DD");

  const allowedRoles = ["superadmin", "superuser"];
  const [datePicker, setDatePicker] = useState(false);
  const [dateData, setDateData] = useState(dateNow || null);
  const [stdDateData, setStdDateData] = useState(stdDateNow || null);
  const [roomId] = useState(params.roomId);
  const [roomData, setRoomData] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [img, setImg] = useState("");
  const [roomAndFacilitiesImg, setRoomAndFacilitiesImg] = useState("");
  const [bookingRoomData, setBookingRoomData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const filterData = JSON.parse(localStorage.getItem("filterRoom")) || {};
  const [userDetail, setUserDetail] = useState();

  const fetchUser = () => {
    let userData = JSON.parse(localStorage.getItem("user_data"));
    Axios({
      method: "GET",
      url: `/admin?user_id=${userData?.user_id}${
        userData?.user_email && `&user_email=${userData?.user_email}`
      }`,
    }).then((result) => {
      if (result.status === 200) {
        setUserDetail({ ...result.data.data.data[0] });
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const loadRoomData = () => {
    Axios({
      method: "GET",
      url: `/room/${roomId}`,
    }).then((res) => {
      let facilityData = res.data.data.data.booking_room_facilities;
      setFacilityData(res.data.data.data.booking_room_facilities);
      setRoomData(res.data.data.data);
      if (res.data.data.data.image) {
        let facilityImage = facilityData.map((data) => {
          let images = data.image;
          return images;
        });

        let roomImage = [res.data.data.data.image];

        setRoomAndFacilitiesImg(roomImage.concat(facilityImage));
        setImg(res.data.data.data.image);
      } else {
        setImg(defaultMeetingRoom);
      }
    });
  };

  const refreshDate = () => {
    setDateData(
      filterData?.date
        ? moment(filterData?.date).format("dddd, DD MMMM YYYY")
        : dateNow
    );
    setStdDateData(
      moment(new Date(filterData?.date || stdDateNow)).format("YYYY-MM-DD")
    );
    if (
      filterData?.date === stdDateTomorrow ||
      filterData?.date === stdDateNow
    ) {
      setDatePicker(false);
    } else {
      setDatePicker(true);
    }
  };

  useEffect(() => {
    loadRoomData();
    // eslint-disable-next-line
  }, [roomId]);

  useEffect(() => {
    refreshDate();
    // eslint-disable-next-line
  }, [dateNow, stdDateNow]);

  useEffect(() => {
    const loadBookingData = async () => {
      setIsLoading(true);
      const date = new Date(stdDateData);

      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      try {
        const { data } = await Axios({
          method: "GET",
          url: `/room/occurance?room_id=${roomId}&status=Booking Confirmed, Booking Created&start_usage_gte=${startDate}&end_usage_lt=${endDate}&order_column=start_usage&order_sequence=ASC`,
        });

        setBookingRoomData(data?.data?.data || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    loadBookingData();
  }, [dateNow, roomId, stdDateData, stdDateNow]);

  const setValue = (event, name) => {
    let tempSetSelectedDate = [...selectedDate];
    tempSetSelectedDate[name] = event;

    if (event === "datepicker") {
      setDateData(
        moment(filterData?.date).format("dddd, DD MMMM YYYY") || null
      );
      setStdDateData(moment(filterData?.date).format("YYYY-MM-DD") || null);
      setDatePicker(true);
    } else if (event === "tomorrow") {
      setDateData(dateTomorrow);
      setStdDateData(stdDateTomorrow);
      setDatePicker(false);
    } else if (event === "today") {
      setDateData(dateNow);
      setStdDateData(stdDateNow);
      setDatePicker(false);
    }

    setSelectedDate(tempSetSelectedDate);
  };

  const handleInput = (event) => {
    setDateData(moment(event.target.value).format("dddd, DD MMMM YYYY"));
    setStdDateData(moment(event.target.value).format("YYYY-MM-DD"));
  };

  return (
    <>
      <Flex className={styles["header-container"]}>
        <MainHeader title="Room Information" backLink="/booking-room?id=2" />
      </Flex>
      <Flex className={styles["main-container"]}>
        <SectionRoomInfoData
          roomData={roomData}
          img={img}
          roomAndFacilitiesImg={roomAndFacilitiesImg}
          facilityData={facilityData}
        />
        <Link to={`/booking-room/booking/${roomId}/${stdDateData}`}>
          <Button bg="#006F95" className={styles["book-room-button"]}>
            Book this room
          </Button>
        </Link>
        <Box>
          <Text className={styles["text-schedules"]}>Booked Schedules</Text>
          <RadioGroup
            name="dateSelector"
            onChange={(event) => setValue(event, "dateSelector")}
            defaultValue={
              filterData
                ? filterData?.date === stdDateTomorrow
                  ? "tomorrow"
                  : filterData?.date === stdDateNow
                  ? "today"
                  : "datepicker"
                : "today"
            }
          >
            <Stack spacing={{ base: 2, sm: 3, md: 5 }} direction="row">
              <Radio value="today" checked="checked">
                <Text fontSize="sm">Today</Text>
              </Radio>
              <Spacer />
              <Radio value="tomorrow">
                <Text fontSize="sm">Tomorrow</Text>
              </Radio>
              <Spacer />
              <Radio value="datepicker">
                <Text fontSize="sm">Choose Date</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box>
          {datePicker && (
            <Box className={styles["input-date"]}>
              <Input
                type="date"
                onChange={handleInput}
                min={dateMin}
                max={
                  allowedRoles.includes(userDetail?.role?.toLowerCase())
                    ? null
                    : dateMax
                }
                defaultValue={filterData?.date || dateData}
              />
            </Box>
          )}
          {dateData ? (
            <>
              <Text className={styles["text-schedules-date"]}>{dateData}</Text>
            </>
          ) : null}
        </Box>
        <Box>
          {isLoading ? (
            <>
              <Skeleton
                height="150px"
                margin="0.5em 0"
                padding="0.5em 1em"
                borderRadius="10px"
                color="rgba(97, 170, 174, 0.15)"
              />
            </>
          ) : bookingRoomData.length > 0 ? (
            bookingRoomData.map((bookingData, index) => (
              <CardScheduleBooked bookingData={bookingData} index={index} />
            ))
          ) : (
            <>
              <Center>
                <Image src={EmptySchedule} height={"250px"} />
              </Center>
              <Text className={styles["no-agenda"]}>
                No bookings for this day
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
}

export default RoomDetails;
