import React from "react";

import Header from "../../../../components/SecondaryHeader";
import BottomNavbar from "../../../../components/Navbars/BottomNavbar";
import History from "./modules/History";
import { Flex } from "@chakra-ui/react";

function HistoryView() {
  return (
    <>
      <Flex position="sticky" top={0}>
        <Header type="all" title="Riwayat" backLink="/" />
      </Flex>
      <History />
      <BottomNavbar />
    </>
  );
}

export default HistoryView;
