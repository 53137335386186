import React from "react";
import { Button } from "@chakra-ui/react";

const ButtonConfirmed = () => {
  return (
    <Button
      size="xs"
      bg="#D9F2E3"
      color="#2A3D4A"
      borderRadius="99px"
      p="0 16px"
      fontSize="12px"
      fontWeight="400"
      width="100%"
    >
      Sudah confirmed
    </Button>
  );
};

export default ButtonConfirmed;
