import React from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import styles from "../bookingdetail.module.css";
import moment from "moment/moment";
import "moment-timezone";
import "moment/locale/id";

const SectionBookingSchedule = ({
  bookingOccurrenceData,
  bookingRoomData,
  isLoading,
  renderActionbuttonBookingRoom,
}) => {
  moment.locale("id");

  return (
    <div>
      <Flex>
        <Text className={styles["section-title"]}>
          {bookingRoomData?.is_repeat ? "Schedules" : "Schedule"}
        </Text>
      </Flex>
      {!isLoading &&
        bookingRoomData &&
        bookingOccurrenceData.map((occurrence, index) => {
          return (
            <Flex>
              <Box className={styles["schedule-card"]} key={`nomer ${index}`}>
                <Flex className={styles["schedule-sub-section"]}>
                  <Box width="120px">
                    {new Date(occurrence?.finish_usage) > new Date() ||
                    occurrence?.status === "Booking Cancelled" ? (
                      <Box
                        className={
                          occurrence?.status === "Booking Created" ||
                          "Booking Confirmed"
                            ? styles["schedule-booking-status-created"]
                            : styles["schedule-booking-status-cancelled"]
                        }
                        alignItems="right"
                        textAlign="right"
                      >
                        <Text className={styles["text-value-status"]}>
                          {occurrence?.status === "Booking Created"
                            ? "Active"
                            : occurrence?.status === "Booking Confirmed"
                            ? "Confirmed"
                            : "Cancelled"}
                        </Text>
                      </Box>
                    ) : (
                      <Box
                        className={styles["schedule-booking-status-completed"]}
                        alignItems="right"
                        textAlign="right"
                      >
                        <Text className={styles["text-value-status"]}>
                          Completed
                        </Text>
                      </Box>
                    )}
                  </Box>
                  <Spacer />
                  <Box width="120px">
                    <Box
                      className={styles["schedule-numbering"]}
                      alignContent="right"
                      textAlign="right"
                    >
                      <Text
                        alignItems="right"
                        className={styles["text-value-number"]}
                      >
                        #{index + 1}
                      </Text>
                    </Box>
                  </Box>
                </Flex>
                <Flex className={styles["schedule-sub-section"]}>
                  <Box width={"35%"}>
                    <Box className={styles["room-detail-info-card"]}>
                      <Text className={styles["text-variable-schedule"]}>
                        Date
                      </Text>
                    </Box>
                    <Box className={styles["room-detail-info-card"]}>
                      <Text className={styles["text-variable-schedule"]}>
                        Time
                      </Text>
                    </Box>
                  </Box>
                  <Box width={"65%"}>
                    <Box className={styles["room-detail-info-card"]}>
                      <Text className={styles["text-value-schedule"]}>
                        :{" "}
                        {moment
                          .utc(occurrence?.start_usage)
                          .tz("Asia/Jakarta")
                          .format("dddd, DD MMMM YYYY")}
                      </Text>
                    </Box>
                    <Box className={styles["room-detail-info-card"]}>
                      <Text className={styles["text-value-schedule"]}>
                        :{" "}
                        {moment
                          .utc(occurrence?.start_usage)
                          .tz("Asia/Jakarta")
                          .format("HH:mm")}{" "}
                        -{" "}
                        {moment
                          .utc(occurrence?.finish_usage)
                          .tz("Asia/Jakarta")
                          .format("HH:mm")}{" "}
                        WIB
                      </Text>
                    </Box>
                  </Box>
                </Flex>
                {occurrence.status === "Booking Cancelled" && (
                  <Flex className={styles["schedule-sub-section-cancel"]}>
                    <Box width={"35%"}>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable-schedule"]}>
                          Cancelled at
                        </Text>
                      </Box>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable-schedule"]}>
                          Cancelled by
                        </Text>
                      </Box>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-variable-schedule"]}>
                          Reason
                        </Text>
                      </Box>
                    </Box>
                    <Box width={"65%"}>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value-schedule"]}>
                          :{" "}
                          {moment(occurrence?.cancel_timestamp).format(
                            "DD MMMM YYYY, HH:mm"
                          )}
                        </Text>
                      </Box>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value-schedule"]}>
                          : {occurrence?.cancelled_by}
                        </Text>
                      </Box>
                      <Box className={styles["room-detail-info-card"]}>
                        <Text className={styles["text-value-schedule"]}>
                          : {occurrence?.cancel_reason}
                        </Text>
                      </Box>
                    </Box>
                  </Flex>
                )}

                {renderActionbuttonBookingRoom(occurrence)}
              </Box>
            </Flex>
          );
        })}
    </div>
  );
};

export default SectionBookingSchedule;
