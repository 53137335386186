import React from "react";
import { Box, Flex, Text, CloseButton } from "@chakra-ui/react";
import { PiWarningCircle } from "react-icons/pi";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { AiOutlineInfoCircle } from "react-icons/ai";

const getToastTheme = (status) => {
  switch (status) {
    case "success":
      return { background: "#D9F2E3", border: "#40BF75" };
    case "error":
      return { background: "#FFEDEF", border: "#E6001C" };
    case "warning":
      return { background: "#FFFBE4", border: "#FFCD00" };
    default:
      return { background: "#CCDDF5", border: "#0056CC" };
  }
};

const iconToast = (status, color) => {
  if (status === "error" || status === "warning") {
    return <PiWarningCircle color={color} size="17px" style={{ marginRight: "8px" }} />;
  } else if (status === "success") {
    return <IoCheckmarkCircleOutline color={color} size="17px" style={{ marginRight: "8px" }} />;
  } else {
    return <AiOutlineInfoCircle color={color} size="17px" style={{ marginRight: "8px" }} />;
  }
};

const CustomToast = ({ title, description, status, onClose }) => {
  const { background, border } = getToastTheme(status);

  return (
    <Box
      bg={background}
      border="1px solid"
      borderColor={border}
      p={3}
      borderRadius="8px"
      w="100%"
      display="flex"
      alignItems="flex-start"
      fontSize="12px"
      lineHeight="16.8px"
      color="#222222"
      boxShadow="lg"
      position="relative"
    >
      {iconToast(status, border)}
      <Flex direction="column" flex="1">
        <Text fontWeight="700">{title}</Text>
        <Text fontWeight="400">{description}</Text>
      </Flex>
      <CloseButton size="sm" onClick={onClose} color={border} mt="-6px" />
    </Box>
  );
};

export default CustomToast;
