import React from "react";
import moment from "moment/moment";
import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  CheckboxGroup,
  Checkbox,
  Center,
  Textarea,
  RadioGroup,
  Radio,
  Wrap,
  WrapItem,
  Stack,
  Image,
} from "@chakra-ui/react";
import styles from "./formsteps.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

function MultiStepsForm({
  params,
  onOpen,
  roomData,
  handleInput,
  formData,
  startDate,
  endDate,
  dateNow,
  dateMax,
  isWeeklyMeeting,
  step,
  prevStep,
  nextStep,
  aulaLayoutType,
  chairType,
  RoomLayoutHandler,
  RadioChangeHandler,
}) {
  moment.locale();

  const MultiStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Text margin={"0.25em 0"} className={styles["text-subtitle"]}>
              Step 1: Basic Information
            </Text>
            <Flex marginBottom={"0.5em"}>
              <Box width={"100%"}>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Meeting Subject<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    onChange={handleInput}
                    variant="filled"
                    name="booking_purpose"
                    defaultValue={formData?.booking_purpose}
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Booked by<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    onChange={handleInput}
                    variant="filled"
                    name="user_name"
                    defaultValue={localStorage.getItem("username")}
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Contact Number<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    type="tel"
                    minLength="8"
                    onChange={handleInput}
                    variant="filled"
                    name="user_contact"
                    defaultValue={formData?.user_contact}
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Number of Attendee<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    onChange={handleInput}
                    placeholder={`Max Capacity: ${roomData.capacity}`}
                    type="tel"
                    variant="filled"
                    name="attendees"
                    defaultValue={formData?.attendees}
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Booking Date<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    disabled
                    onChange={handleInput}
                    type="date"
                    variant="filled"
                    name="booking_at"
                    min={dateNow}
                    max={dateMax}
                    defaultValue={params.bookingDate}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex marginBottom={"1em"}>
              <Box width={"45%"}>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Start<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    disabled
                    onChange={handleInput}
                    type="time"
                    variant="filled"
                    id="start_usage"
                    name="start_usage"
                    min={dateNow}
                    value={startDate}
                    defaultValue={startDate}
                  />
                </Flex>
              </Box>
              <Box width={"10%"} />
              <Box width={"45%"}>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    End<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <Flex>
                  <Input
                    disabled
                    onChange={handleInput}
                    type="time"
                    variant="filled"
                    id="finish_usage"
                    name="finish_usage"
                    min={dateNow}
                    value={endDate}
                    defaultValue={endDate}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex marginBottom={"1.25em"}>
              <Center width="100%">
                <CheckboxGroup>
                  <Checkbox
                    name="isWeeklyMeeting"
                    onChange={handleInput}
                    defaultValue={formData?.isWeeklyMeeting}
                  >
                    Weekly Meeting?
                  </Checkbox>
                </CheckboxGroup>
                <Flex className={styles["room-detail-info"]}>
                  <Center>
                    {isWeeklyMeeting && (
                      <>
                        <Text className={styles["text-variable"]} paddingRight="10px">
                          Until:
                        </Text>
                        <Input
                          onChange={handleInput}
                          min={dateNow}
                          max={dateMax}
                          name="weeklyMeetingDeadline"
                          defaultValue={formData?.weeklyMeetingDeadline}
                          type="date"
                          size="sm"
                          variant="filled"
                        />
                      </>
                    )}
                  </Center>
                </Flex>
              </Center>
            </Flex>
            <Button
              className={styles["book-room-button"]}
              bg="#006F95"
              _focus={{ bg: "#006F95" }}
              onClick={nextStep}
              rightIcon={<FontAwesomeIcon icon={faAngleRight} size="md" />}
            >
              Next
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Text margin={"0.5em 0"} className={styles["text-subtitle"]}>
              Step 2: Layout
            </Text>
            <Flex marginBottom={"0.25em"}>
              <Box width={"100%"}>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>
                    Pilih Layout Ruang:<span style={{ color: "red" }}>*</span>
                  </Text>
                </Flex>
                <RadioGroup
                  name="aulaChosenLayout"
                  onChange={(event) => RoomLayoutHandler(event, "aulaChosenLayout", "radio")}
                  defaultValue={formData.aulaChosenLayout}
                >
                  <Wrap spacing="1em" direction="row">
                    <WrapItem>
                      <Stack>
                        <Box>
                          <Radio value="uShape">
                            <div className={styles["choose-aula-layout"]}>
                              <Image
                                src={"https://i.ibb.co/MC9SqZL/SD-default-image.png"}
                                mb={2}
                                boxSize="100%"
                              />
                              U Shape
                            </div>
                          </Radio>
                        </Box>
                      </Stack>
                    </WrapItem>
                    <WrapItem>
                      <Stack>
                        <Box>
                          <Radio value="classroom">
                            <div className={styles["choose-aula-layout"]}>
                              <Image
                                src={"https://i.ibb.co/MC9SqZL/SD-default-image.png"}
                                mb={2}
                                boxSize="100%"
                              />
                              Classroom
                            </div>
                          </Radio>
                        </Box>
                      </Stack>
                    </WrapItem>
                    <WrapItem>
                      <Stack>
                        <Box>
                          <Radio value="roundTable">
                            <div className={styles["choose-aula-layout"]}>
                              <Image
                                src={"https://i.ibb.co/MC9SqZL/SD-default-image.png"}
                                mb={2}
                                boxSize="100%"
                              />
                              Round Table
                            </div>
                          </Radio>
                        </Box>
                      </Stack>
                    </WrapItem>
                    <WrapItem>
                      <Stack>
                        <Box>
                          <Radio value="lesehan">
                            <div className={styles["choose-aula-layout"]}>
                              <Image
                                src={"https://i.ibb.co/MC9SqZL/SD-default-image.png"}
                                mb={2}
                                boxSize="100%"
                              />
                              Lesehan
                            </div>
                          </Radio>
                        </Box>
                      </Stack>
                    </WrapItem>
                    <WrapItem>
                      <Stack>
                        <Box>
                          <Radio value="others">
                            <div className={styles["choose-aula-layout"]}>
                              <Image
                                src={"https://i.ibb.co/MC9SqZL/SD-default-image.png"}
                                mb={3}
                                boxSize="100%"
                              />
                              Others
                            </div>
                          </Radio>
                        </Box>
                      </Stack>
                    </WrapItem>
                  </Wrap>
                </RadioGroup>
              </Box>
            </Flex>
            {aulaLayoutType === "uShape" || aulaLayoutType === "classroom" ? (
              <Flex marginBottom={"1.25em"}>
                <Box width="100%">
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>
                      Pilih Jenis Kursi:<span style={{ color: "red" }}>*</span>
                    </Text>
                  </Flex>
                  <RadioGroup
                    name="selectedSeatOption"
                    onChange={(event) => RadioChangeHandler(event, "selectedSeatOption", "radio")}
                    defaultValue={formData?.selectedSeatOption}
                  >
                    <Box marginBottom="0.5em">
                      <Radio marginBottom="0.5em" value="kursiKampus">
                        Kursi Kampus
                      </Radio>
                      <Input
                        type="tel"
                        variant="filled"
                        id="kursiKampusAmount"
                        name="kursiKampusAmount"
                        defaultValue={formData?.kursiKampusAmount}
                        onChange={(event) =>
                          RadioChangeHandler(event, "kursiKampusAmount", "input")
                        }
                        placeholder="Masukkan Jumlah"
                        disabled={chairType !== "kursiKampus" && "disabled"}
                      />
                    </Box>
                    <Box>
                      <Radio marginBottom="0.5em" value="kursiStandar">
                        Kursi Standar
                      </Radio>
                      <Input
                        type="tel"
                        variant="filled"
                        id="kursiStandardAmount"
                        name="kursiStandardAmount"
                        defaultValue={formData?.kursiStandardAmount}
                        onChange={(event) =>
                          RadioChangeHandler(event, "kursiStandardAmount", "input")
                        }
                        placeholder="Masukkan Jumlah"
                        disabled={chairType !== "kursiStandar" && "disabled"}
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              </Flex>
            ) : aulaLayoutType === "roundTable" ? (
              <Flex marginBottom={"1.25em"}>
                <Box width="100%">
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>
                      Jumlah Meja<span style={{ color: "red" }}>*</span>
                    </Text>
                  </Flex>
                  <Flex>
                    <Input
                      type="tel"
                      onChange={handleInput}
                      variant="filled"
                      id="tableAmount"
                      name="tableAmount"
                      defaultValue={formData?.tableAmount}
                      placeholder="Masukkan Jumlah"
                    />
                  </Flex>
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>
                      Jumlah Kursi per Meja<span style={{ color: "red" }}>*</span>
                    </Text>
                  </Flex>
                  <Flex>
                    <Input
                      type="tel"
                      onChange={handleInput}
                      variant="filled"
                      id="chairPerTableAmount"
                      name="chairPerTableAmount"
                      defaultValue={formData?.chairPerTableAmount}
                      placeholder="Masukkan Jumlah"
                    />
                  </Flex>
                </Box>
              </Flex>
            ) : aulaLayoutType === "lesehan" ? (
              <Flex marginBottom={"1.25em"}>
                <Box width="100%">
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>
                      Luas Area Lesehan<span style={{ color: "red" }}>*</span>
                    </Text>
                  </Flex>
                  <Flex>
                    <textarea
                      maxlength="255"
                      className={styles["input-style-text-area"]}
                      onChange={handleInput}
                      id="lesehanArea"
                      name="lesehanArea"
                      variant="filled"
                      defaultValue={formData?.lesehanArea}
                      placeholder="Masukkan Luas Area (mis: 1/3, 1/2, Full)"
                    />
                  </Flex>
                </Box>
              </Flex>
            ) : aulaLayoutType === "others" ? (
              <Flex marginBottom={"1.25em"}>
                <Box width="100%">
                  <Flex className={styles["room-detail-info"]}>
                    <Text className={styles["text-variable"]}>
                      Detail Layout<span style={{ color: "red" }}>*</span>
                    </Text>
                  </Flex>
                  <Flex>
                    <Textarea
                      type="text"
                      onChange={handleInput}
                      maxlength="255"
                      variant="filled"
                      id="aulaLayoutDetail"
                      name="aulaLayoutDetail"
                      defaultValue={formData?.aulaLayoutDetail}
                      placeholder="Masukkan Detail Layout Ruangan, max 255 karakter"
                    />
                  </Flex>
                </Box>
              </Flex>
            ) : (
              <Flex marginBottom={"1.25em"} />
            )}
            <Flex>
              <Box width={"47.5%"}>
                <Button
                  className={styles["prev-next-button"]}
                  color="#fff"
                  bg="#006F95"
                  _focus={{ bg: "#006F95" }}
                  onClick={prevStep}
                  leftIcon={<FontAwesomeIcon icon={faAngleLeft} size="md" />}
                >
                  Prev
                </Button>
              </Box>
              <Box width={"5%"} />
              <Box width={"47.5%"}>
                <Button
                  className={styles["prev-next-button"]}
                  color="#fff"
                  bg="#006F95"
                  _focus={{ bg: "#006F95" }}
                  onClick={nextStep}
                  rightIcon={<FontAwesomeIcon icon={faAngleRight} size="md" />}
                >
                  Next
                </Button>
              </Box>
            </Flex>
          </>
        );
      case 3:
        return (
          <>
            <Text margin={"0.5em 0 1.5em 0"} className={styles["text-subtitle"]}>
              Step 3: Supporting Facilities
            </Text>
            <Text className={styles["text-subtitle-2"]}>Masukkan jumlah fasilitas pendukung</Text>
            <Flex marginBottom={"1.25em"}>
              <Box width={"100%"}>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-sub-variable"]}>Flipchart</Text>
                </Flex>
                <Flex>
                  <input
                    onChange={handleInput}
                    type="tel"
                    class={styles["input-style"]}
                    id="flipchartAmount"
                    name="flipchartAmount"
                    defaultValue={formData?.flipchartAmount}
                    placeholder="Jumlah Item (Max 5)"
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-sub-variable"]}>Papan Whiteboard</Text>
                </Flex>
                <Flex>
                  <input
                    onChange={handleInput}
                    type="tel"
                    variant="filled"
                    className={styles["input-style"]}
                    id="whiteboardAmount"
                    name="whiteboardAmount"
                    defaultValue={formData?.whiteboardAmount}
                    placeholder="Jumlah Item (Max 5)"
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-sub-variable"]}>Meja Kotak</Text>
                </Flex>
                <Flex>
                  <Input
                    onChange={handleInput}
                    type="tel"
                    variant="filled"
                    name="squareTableAmount"
                    placeholder="Jumlah Item (Max 5)"
                    min="0"
                    max="5"
                    defaultValue={formData?.squareTableAmount}
                  />
                </Flex>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-sub-variable"]}>Kabel Roll</Text>
                </Flex>
                <Flex>
                  <Input
                    onChange={handleInput}
                    type="tel"
                    variant="filled"
                    name="cableRollAmount"
                    placeholder="Jumlah Item (Max 5)"
                    min="0"
                    max="5"
                    defaultValue={formData?.cableRollAmount}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex>
              <Box width={"47.5%"}>
                <Button
                  className={styles["prev-next-button"]}
                  bg="#006F95"
                  _focus={{ bg: "#006F95" }}
                  onClick={prevStep}
                  leftIcon={<FontAwesomeIcon icon={faAngleLeft} size="md" />}
                >
                  Prev
                </Button>
              </Box>
              <Box width={"5%"} />
              <Box width={"47.5%"}>
                <Button
                  className={styles["prev-next-button"]}
                  bg="#006F95"
                  _focus={{ bg: "#006F95" }}
                  onClick={nextStep}
                  rightIcon={<FontAwesomeIcon icon={faAngleRight} size="md" />}
                >
                  Next
                </Button>
              </Box>
            </Flex>
          </>
        );
      case 4:
        return (
          <>
            <Text margin={"0.5em 0"} className={styles["text-subtitle"]}>
              Step 4: Additional Information
            </Text>
            <Flex marginBottom={"1.25em"}>
              <Box width={"100%"}>
                <Flex className={styles["room-detail-info"]}>
                  <Text className={styles["text-variable"]}>Additional Information</Text>
                </Flex>
                <Flex>
                  <Textarea
                    onChange={handleInput}
                    maxlength="255"
                    variant="filled"
                    name="additionalInformationAula"
                    defaultValue={formData?.additionalInformationAula}
                    placeholder="Masukkan Informasi Tambahan, max 255 karakter"
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex>
              <Box width={"25%"} marginBottom={"1em"}>
                <Button
                  className={styles["prev-next-button"]}
                  bg="#006F95"
                  _focus={{ bg: "#006F95" }}
                  onClick={prevStep}
                  leftIcon={<FontAwesomeIcon icon={faAngleLeft} size="md" />}
                >
                  Prev
                </Button>
              </Box>
              <Box width={"75%"} />
            </Flex>
            <Flex>
              <Box width={"100%"}>
                <Button className={styles["book-room-button"]} bg="#006F95" onClick={onOpen}>
                  Create Booking
                </Button>
              </Box>
            </Flex>
          </>
        );
      default:
      // do nothing
    }
  };

  return (
    <>
      <Flex className={styles["main-container"]}>
        <Box>
          <Text className={styles["text-title"]}>
            Booking Form (Aula):
            <br />
            {roomData?.name}
          </Text>
        </Box>
        <Box>{MultiStep()}</Box>
      </Flex>
    </>
  );
}

export default MultiStepsForm;
