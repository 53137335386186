import React from "react";
import { Flex } from "@chakra-ui/react";

const AlignmentButton = ({ children }) => {
  return (
    <Flex
      direction="row"
      gap="8px"
      marginTop="4px"
      justifyContent="space-evenly"
    >
      {children}
    </Flex>
  );
};

export default AlignmentButton;
